// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "channels"
import "jquery-lazyload"

Rails.start()

$(() => {
  $('.btn-add').on('click', (e) => {
    let input = $(e.target).parent().parent().find('.quantity-input'),
        current = parseInt(input.val()),
        max = input.data('max') == '0' ? 0 : (parseInt(input.data('max')) || 99)

    if (current < max)
      $(e.target).parent().parent().find('.quantity-input').val(current + 1)
  })

  $('.btn-remove').on('click', (e) => {
    let current = parseInt($(e.target).parent().parent().find('.quantity-input').val())

    if (current > 0)
    $(e.target).parent().parent().find('.quantity-input').val(current - 1)
  })

  $('#order').on('submit', function(e) {
    e.preventDefault()

    let $form = $(this)

    Swal.fire({
        html: "<strong>Atenção:</strong> Apesar do PagSeguro solicitar um endereço de entrega, <u>nós não realizaremos a entrega dos produtos</u>. O comprador deverá ser responsável pela retirada dos mesmos no endereço informado neste site.",
        icon: 'info',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showCancelButton: false,
        confirmButtonText: 'Continuar',
        reverseButtons: true
    }).then((result) => {
        if (result.value) {
            $form.unbind().submit()
        }
    })
  })
})